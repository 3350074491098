
import {computed, defineComponent, ref} from "vue";
import {
  IonButton,
  IonCol,
  IonGrid, IonIcon,
  IonItem,
  IonLabel,
  IonRow, IonSearchbar, modalController,
} from "@ionic/vue";
import {Order} from "@/domain/model/order/order.model";
import MaterialCreatePage from "@/app/view/material/create/MaterialCreatePage.vue";
import OrderUpdatePage from "@/app/view/order/update/OrderUpdatePage.vue";
import {CreateExitDelivery, CreateExitDeliveryItem} from "@/domain/command/delivery/delivery.command";
import _ from "lodash";
import {checkmarkCircleOutline} from "ionicons/icons";
import {Material} from "@/domain/model/material/material.model";
import {useStore} from "vuex";
import CreateExitDeliveryOrdersCardItemComponent
  from "@/app/view/delivery/create/CreateExitDeliveryOrdersCardItemComponent.vue";

export default defineComponent({
  name: "CreateExitDeliveryOrdersCardComponent",
  components: {
    CreateExitDeliveryOrdersCardItemComponent,
    // IonButton,
    // IonLabel,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    // IonIcon,
    IonSearchbar,
  },
  props: {
    orders: null,
    exitDeliveryCommand: null,
  },
  setup(prop: any) {
    // const ordersProp = ref<Order[]>(prop.orders);
    // const commandProp = ref<CreateExitDelivery>(prop.exitDeliveryCommand);

    const maxResults = ref(4);
    const searchWord = ref<string>('');
    const searchOrder = (order: Order, word: string): boolean => order.material.ref.toLowerCase().includes(word.toLowerCase()) || order.material.name.toLowerCase().includes(word.toLowerCase());
    const filterOrder = (orders: Order[], word: string): Order[] => _.filter(orders, (orderNode: Order) => searchOrder(orderNode, word));
    const sortOrder = (orders: Order[]): Order[] => _.sortBy(orders, ['receivedDate', 'asc']);
    const ordersList = computed<Order[]>(() => sortOrder(filterOrder(prop.orders, searchWord.value)));//.slice(0, maxResults.value))
    // const onOpenModalCreateExitDeliveryItem = async (order: Order) => {
    //   const modal = await modalController
    //       .create({
    //         component: OrderUpdatePage,
    //         componentProps: {
    //           order: order,
    //           command: prop.exitDeliveryCommand,
    //         },
    //       });
    //   return modal.present();
    // };
    // const isOrderTook = (id: string) => {
    //   return _.some(prop.exitDeliveryCommand.items, (item: CreateExitDeliveryItem) => item.createProducedOrder.orderId === id);
    // }
    // const isOrderTook = computed((id: string) => {
    //   return _.some(prop.exitDeliveryCommand.items, (item: CreateExitDeliveryItem) => item.createProducedOrder.orderId === id);
    // })



    return {
      // ordersProp,
      // onOpenModalCreateExitDeliveryItem,
      // isOrderTook,
      checkmarkCircleOutline,
      searchWord,
      ordersList
    };
  }
})
