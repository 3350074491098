
import {computed, defineComponent, ref} from "vue";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem, IonItemOption,
  IonItemSliding,
  IonLabel,
  IonRow,
  isPlatform,
  IonItemOptions,
  onIonViewWillEnter
} from "@ionic/vue";
import {CreateExitDelivery, CreateExitDeliveryItem} from "@/domain/command/delivery/delivery.command";
import {useStore} from "vuex";
import {Order} from "@/domain/model/order/order.model";
import _ from "lodash";

export default defineComponent({
  name: "CreateExitDeliveryTableItemComponent",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonButton,
    IonLabel,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
  },
  props: {
    exitDeliveryCommand: null,
    exitDeliveryItemList: null,
  },
  setup(prop: any, {emit}) {
    const store = useStore();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const createExitDelivery = ref<CreateExitDelivery>(prop.exitDeliveryCommand);
    const takeOrderOfDeliveryCommand = (item: CreateExitDeliveryItem): Order => {
      return store.getters.orderById(item.createProducedOrder.orderId);
    }
    createExitDelivery.value.items =
        _.reject(createExitDelivery.value.items, (item: CreateExitDeliveryItem) => {
          const order = takeOrderOfDeliveryCommand(item);
          if(!order)
            return true;
          else
            return takeOrderOfDeliveryCommand(item).material.ref === ''
        });


    const onDeleteItem = (id: string) => {
      createExitDelivery.value.items = _.filter(createExitDelivery.value.items, (item: CreateExitDeliveryItem) => item.id !== id);
      emit('on-update-items', createExitDelivery.value.items);
    }

    return {
      createExitDelivery,
      takeOrderOfDeliveryCommand,
      onDeleteItem,
      isTablet,
    };
  }
})
