
import {computed, defineComponent} from "vue";
import {IonButton, IonCol, IonIcon, IonItem, IonLabel, IonRow, modalController} from "@ionic/vue";
import _ from "lodash";
import {CreateExitDeliveryItem} from "@/domain/command/delivery/delivery.command";
import {Order} from "@/domain/model/order/order.model";
import OrderUpdatePage from "@/app/view/order/update/OrderUpdatePage.vue";
import {checkmarkCircleOutline} from "ionicons/icons";

export default defineComponent({
  name: "CreateExitDeliveryOrdersCardItemComponent",
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonButton,
    IonIcon,
    IonLabel,
  },
  props: {
    order: null,
    exitDeliveryCommand: null,
  },
  setup(prop: any) {


    const isOrderTook = computed(() => {
      return _.some(prop.exitDeliveryCommand.items, (item: CreateExitDeliveryItem) => item.createProducedOrder.orderId === prop.order.id);
    })

    const onOpenModalCreateExitDeliveryItem = async (order: Order) => {
      const modal = await modalController
          .create({
            component: OrderUpdatePage,
            componentProps: {
              order: order,
              command: prop.exitDeliveryCommand,
            },
          });
      return modal.present();
    };

    return {
      onOpenModalCreateExitDeliveryItem,
      isOrderTook,
      checkmarkCircleOutline,
    };
  }
})
